import Avatar from '@material-ui/core/Avatar';
import {
  AccountCircle,
  // Assistant,
  Contacts,
  Description,
  EditAttributes,
  // Event,
  Favorite,
  Gavel,
  Home,
  ImportContacts,
  Language,
  // Message,
  // Pages,
  Policy,
  School,
  Security,
  Settings,
  ThumbsUpDown,
  Tune,
  Work,
} from '@material-ui/icons';
import React from 'react';
import i18n from '../../i18n';
// import { StyledBadge } from './styles';
import { selectUserProfile } from 'App/features/Profile';
import { useAppSelector } from '../../App/hooks';
import { UserProfileInterface } from '../../App/features/Profile/types';
import styles from './Navigation.module.scss';

export const gradient = (
  <svg width={0} height={0} style={{ margin: 0 }}>
    <linearGradient id="linearColors" x1={1} y1={1} x2={0} y2={1}>
      <stop offset={0} stopColor="#00a9a8" />
      <stop offset={1} stopColor="#00799b" />
    </linearGradient>
  </svg>
);

const UserAvatar: React.FC = () => {
  const userProfile: UserProfileInterface = useAppSelector(selectUserProfile);
  const userCapitals = userProfile?.firstname.slice(0, 1) + ' ' + userProfile?.lastname.slice(0, 1);
  const avatar = userProfile?.avatar?.urls && userProfile.avatar.urls[2];

  return avatar ? (
    <Avatar src={avatar} style={{ fill: 'url(#linearColors)', width: 24, height: 24 }} />
  ) : (
    <Avatar className={styles.avatar}>
      <span className={styles.avatarInitials}>{userCapitals}</span>
    </Avatar>
  );
};

export const userProfileMenu = {
  links: [
    {
      title: '',
      href: '/account/profile/edit',
      icon: <UserAvatar />,
      activeIcon: (
        <>
          {gradient}
          <UserAvatar />
        </>
      ),
    },
    // --------------------------- For Future Development, Do Not Delete -------------------
    // {
    //   title: 'subjectOptions.messages',
    //   href: '/account/messages',
    //   icon: (
    //     <StyledBadge badgeContent={4} color="error">
    //       <Message />
    //     </StyledBadge>
    //   ),
    //   activeIcon: (
    //     <>
    //       {gradient}
    //       <StyledBadge badgeContent={4} color="error">
    //         <Message style={{ fill: 'url(#linearColors)' }} />
    //       </StyledBadge>
    //     </>
    //   ),
    // },
    // {
    //   title: 'constants.events',
    //   href: '/account/events',
    //   icon: (
    //     <StyledBadge badgeContent={4} color="error">
    //       <Event />
    //     </StyledBadge>
    //   ),
    //   activeIcon: (
    //     <>
    //       {gradient}
    //       <StyledBadge badgeContent={4} color="error">
    //         <Event style={{ fill: 'url(#linearColors)' }} />
    //       </StyledBadge>
    //     </>
    //   ),
    // },
    // --------------------------- For Future Development, Do Not Delete -------------------
  ],
};

function updateUserProfileMenu() {
  userProfileMenu.links[0].title = '';
  // --------------------------- For Future Development, Do Not Delete -------------------
  // userProfileMenu.links[1].title = i18n.t('helpCentreCareerTranslate:subjectOptions.messages', { ns: 'navigation' });
  // userProfileMenu.links[2].title = i18n.t('helpCentreCareerTranslate:subjectOptions.events', { ns: 'navigation' });
  // --------------------------- For Future Development, Do Not Delete -------------------
}

const homeMenu = {
  links: [
    {
      title: 'constants.home',
      href: '/search',
      icon: <Home />,
      activeIcon: (
        <>
          {gradient}
          <Home style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.career',
      href: '/search',
      icon: <Work />,
      activeIcon: (
        <>
          {gradient}
          <Work style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

function updateHomeMenu() {
  homeMenu.links[0].title = i18n.t('constants.home', { ns: 'navigation' });
  homeMenu.links[1].title = i18n.t('constants.career', { ns: 'navigation' });
}

const signedInHomeMenu = {
  links: [
    {
      title: 'navigation:constants.home',
      href: '/',
      icon: <Home />,
      activeIcon: (
        <>
          {gradient}
          <Home style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'navigation:constants.career',
      href: '/search',
      icon: <Work />,
      activeIcon: (
        <>
          {gradient}
          <Work style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    // --------------------------- For Future Development, Do Not Delete -------------------
    // {
    //   title: 'helpCentreCareerTranslate:subjectOptions.pages',
    //   href: '/account/profile/edit',
    //   icon: <Pages />,
    //   activeIcon: (
    //     <>
    //       {gradient}
    //       <Pages style={{ fill: 'url(#linearColors)' }} />
    //     </>
    //   ),
    // },
    // --------------------------- For Future Development, Do Not Delete -------------------
  ],
};

export const settingsMenu = {
  backRoute: '/',
  backTitle: i18n.t('constants.home', { ns: 'navigation' }),
  links: [
    {
      title: 'constants.personalProfile',
      href: '/account/profile/edit',
      icon: <AccountCircle />,
      activeIcon: (
        <>
          {gradient}
          <AccountCircle style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.careerProfile',
      href: '/account/education/edit',
      icon: <Work />,
      activeIcon: (
        <>
          {gradient}
          <Work style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.jobPreferences',
      href: '/account/job-search/edit',
      icon: <Tune />,
      activeIcon: (
        <>
          {gradient}
          <Tune style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.socialProfile',
      href: '/account/add-contacts/edit',
      icon: <Contacts />,
      activeIcon: (
        <>
          {gradient}
          <Contacts style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.account',
      href: '/account/security',
      icon: <Settings />,
      activeIcon: (
        <>
          {gradient}
          <Settings style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

function updateSettingsMenu() {
  settingsMenu.backTitle = i18n.t('constants.home', { ns: 'navigation' });
  settingsMenu.links[0].title = i18n.t('constants.personalProfile', { ns: 'navigation' });
  settingsMenu.links[1].title = i18n.t('constants.careerProfile', { ns: 'navigation' });
  settingsMenu.links[2].title = i18n.t('constants.jobPreferences', { ns: 'navigation' });
  settingsMenu.links[3].title = i18n.t('constants.socialProfile', { ns: 'navigation' });
  settingsMenu.links[4].title = i18n.t('constants.account', { ns: 'navigation' });
}

export const careerProfileMenu = {
  backRoute: '/account/profile/edit',
  backTitle: 'constants.settings',
  links: [
    {
      title: 'Resume',
      href: '/account/resume/edit',
      icon: <Description />,
      activeIcon: (
        <>
          {gradient}
          <Description style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.education',
      href: '/account/education/edit',
      icon: <School />,
      activeIcon: (
        <>
          {gradient}
          <School style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.workExperience',
      href: '/account/experience/edit',
      icon: <Work />,
      activeIcon: (
        <>
          {gradient}
          <Work style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.skills',
      href: '/account/skills/edit',
      icon: <EditAttributes />,
      activeIcon: (
        <>
          {gradient}
          <EditAttributes style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.languages',
      href: '/account/languages/edit',
      icon: <Language />,
      activeIcon: (
        <>
          {gradient}
          <Language style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

function updateCareerProfileMenu() {
  careerProfileMenu.backTitle = i18n.t('constants.settings', { ns: 'navigation' });
  careerProfileMenu.links[1].title = i18n.t('constants.education', { ns: 'navigation' });
  careerProfileMenu.links[2].title = i18n.t('constants.workExperience', { ns: 'navigation' });
  careerProfileMenu.links[3].title = i18n.t('constants.skills', { ns: 'navigation' });
  careerProfileMenu.links[4].title = i18n.t('constants.languages', { ns: 'navigation' });
}

export const jobPreferencesMenu = {
  backRoute: '/account/profile/edit',
  backTitle: 'constants.settings',
  links: [
    {
      title: 'constants.jobPreferences',
      href: '/account/job-search/edit',
      icon: <Tune />,
      activeIcon: (
        <>
          {gradient}
          <Tune style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.workplace',
      href: '/account/workplace/edit',
      icon: <Settings />,
      activeIcon: (
        <>
          {gradient}
          <Settings style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

function updateJobPreferencesMenu() {
  jobPreferencesMenu.backTitle = i18n.t('constants.settings', { ns: 'navigation' });
  jobPreferencesMenu.links[0].title = i18n.t('constants.jobPreferences', { ns: 'navigation' });
  jobPreferencesMenu.links[1].title = i18n.t('constants.workplace', { ns: 'navigation' });
}

export const socialProfileMenu = {
  backRoute: '/account/profile/edit',
  backTitle: 'constants.settings',
  links: [
    {
      title: 'constants.connections',
      href: '/account/add-contacts/edit',
      icon: <Contacts />,
      activeIcon: (
        <>
          {gradient}
          <Contacts style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.personalInterests',
      href: '/account/interests/edit',
      icon: <Favorite />,
      activeIcon: (
        <>
          {gradient}
          <Favorite style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.socialAccounts',
      href: '/account/network/edit',
      icon: <ImportContacts />,
      activeIcon: (
        <>
          {gradient}
          <ImportContacts style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

function updateSocialProfileMenu() {
  socialProfileMenu.backTitle = i18n.t('constants.settings', { ns: 'navigation' });
  socialProfileMenu.links[0].title = i18n.t('constants.connections', { ns: 'navigation' });
  socialProfileMenu.links[1].title = i18n.t('constants.personalInterests', { ns: 'navigation' });
  socialProfileMenu.links[2].title = i18n.t('constants.socialAccounts', { ns: 'navigation' });
}

export const accountMenu = {
  backRoute: '/account/profile/edit',
  backTitle: 'constants.settings',
  links: [
    {
      title: 'constants.security',
      href: '/account/security',
      icon: <Security />,
      activeIcon: (
        <>
          {gradient}
          <Security style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.languageRegion',
      href: '/account/language',
      icon: <Language />,
      activeIcon: (
        <>
          {gradient}
          <Language style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.accountStatus',
      href: '/account/status',
      icon: <Settings />,
      activeIcon: (
        <>
          {gradient}
          <Settings style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

function updateAccountMenu() {
  accountMenu.backTitle = i18n.t('constants.settings', { ns: 'navigation' });
  accountMenu.links[0].title = i18n.t('constants.security', { ns: 'navigation' });
  accountMenu.links[1].title = i18n.t('constants.languageRegion', { ns: 'navigation' });
  accountMenu.links[2].title = i18n.t('constants.accountStatus', { ns: 'navigation' });
}

export const signedInCareerMenu = {
  backRoute: '/',
  backTitle: 'constants.home',
  links: [
    {
      title: 'constants.jobs',
      href: '/search',
      icon: <Work />,
      activeIcon: (
        <>
          {gradient}
          <Work style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    // --------------------------- For Future Development, Do Not Delete -------------------
    {
      title: 'constants.applications',
      href: '/applications', // Application Component is empty
      icon: <ThumbsUpDown />,
      activeIcon: (
        <>
          {gradient}
          <ThumbsUpDown style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    // {
    //   title: 'constants.referrals',
    //   href: '/referrals', // Referrals Component does not exists
    //   icon: <Assistant />,
    //   activeIcon: (
    //     <>
    //       {gradient}
    //       <Assistant style={{ fill: 'url(#linearColors)' }} />
    //     </>
    //   ),
    // },
    // --------------------------- For Future Development, Do Not Delete -------------------
    {
      title: 'constants.jobPreferences',
      href: '/career/job-search/edit',
      icon: <Tune />,
      activeIcon: (
        <>
          {gradient}
          <Tune style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

function updateSignedInCareerMenu() {
  signedInCareerMenu.backTitle = i18n.t('constants.home', { ns: 'navigation' });
  signedInCareerMenu.links[0].title = i18n.t('constants.jobs', { ns: 'navigation' });
  // --------------------------- For Future Development, Do Not Delete -------------------
  signedInCareerMenu.links[1].title = i18n.t('constants.applications', { ns: 'navigation' });
  // signedInCareerMenu.links[2].title = i18n.t('constants.referrals', { ns: 'navigation' });
  // --------------------------- For Future Development, Do Not Delete -------------------
  signedInCareerMenu.links[2].title = i18n.t('constants.jobPreferences', { ns: 'navigation' });
}

export const careerMenu = {
  backRoute: '/',
  backTitle: 'constants.home',
  links: [
    {
      title: 'constants.jobs',
      href: '/search',
      icon: <Work />,
      activeIcon: (
        <>
          {gradient}
          <Work style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

function updateCareerMenu() {
  careerMenu.backTitle = i18n.t('constants.home', { ns: 'navigation' });
  careerMenu.links[0].title = i18n.t('constants.jobs', { ns: 'navigation' });
}

export const helpMenu = {
  backRoute: '/',
  backTitle: 'constants.home',
  links: [],
};

function updateHelpMenu() {
  helpMenu.backTitle = i18n.t('constants.home', { ns: 'navigation' });
}

export const usingNoviopusMenuCareer = {
  backRoute: '/help/career?id=help',
  backTitle: 'Career Help',
  // links: [
  //   { href: '/help/career/using-noviopus?id=general', title: 'navigation:constants.general' },
  //   { href: '/help/career/using-noviopus?id=create-an-account', title: 'navigation:constants.createAccount' },
  //   { href: '/help/career/using-noviopus?id=create-your-profile', title: 'navigation:constants.createProfile' },
  //   { href: '/help/career/using-noviopus?id=career', title: 'constants.career' },
  //   { href: '/help/career/using-noviopus?id=settings', title: 'constants.settings' },
  // ],
};

export const faqMenuCareer = {
  backRoute: '/help/career?id=help',
  backTitle: 'Career Help',
  // links: [
  //   { href: '/help/career/faq?id=new-account', title: 'navigation:constants.newAccount' },
  //   { href: '/help/career/faq?id=confirm-email', title: 'navigation:constants.confirmEmail' },
  //   { href: '/help/career/faq?id=confirm-email-issues', title: 'navigation:constants.confirmEmailIssues' },
  //   { href: '/help/career/faq?id=sign-in', title: 'navigation:constants.signIn' },
  //   { href: '/help/career/faq?id=reset-password', title: 'navigation:constants.resetPassword' },
  //   { href: '/help/career/faq?id=job-search', title: 'navigation:constants.jobSearch' },
  //   { href: '/help/career/faq?id=apply-decline-jobs', title: 'navigation:constants.applyDeclineJobs' },
  //   { href: '/help/career/faq?id=personal-profile', title: 'navigation:constants.personalProfile' },
  //   { href: '/help/career/faq?id=career-profile', title: 'navigation:constants.careerProfile' },
  //   { href: '/help/career/faq?id=job-preferences', title: 'navigation:constants.jobPreferences' },
  //   { href: '/help/career/faq?id=workplace-preferences', title: 'navigation:constants.workplacePreferences' },
  //   { href: '/help/career/faq?id=connections', title: 'navigation:constants.connections' },
  //   { href: '/help/career/faq?id=personal-interests', title: 'navigation:constants.personalInterests' },
  //   { href: '/help/career/faq?id=change-password', title: 'navigation:constants.changePassword' },
  //   { href: '/help/career/faq?id=suspend-account', title: 'navigation:constants.suspendAccount' },
  //   { href: '/help/career/faq?id=delete-account', title: 'navigation:constants.deleteAccount' },
  // ],
};

export const usingNoviopusMenuBusiness = {
  backRoute: '/help/business?id=help',
  backTitle: 'Business Help',
  // links: [
  //   { href: '/help/business/using-noviopus?id=general', title: 'navigation:constants.general' },
  //   {
  //     href: '/help/business/using-noviopus?id=noviopus-business',
  //     title: 'usingNoviopusBusinessTranslate:usingNoviopus',
  //   },
  //   { href: '/help/business/using-noviopus?id=account-type', title: 'navigation:constants.accountType' },
  //   { href: '/help/business/using-noviopus?id=homepage', title: 'navigation:constants.homepage' },
  //   { href: '/help/business/using-noviopus?id=company', title: 'navigation:constants.company' },
  //   { href: '/help/business/using-noviopus?id=jobs', title: 'navigation:constants.jobs' },
  //   { href: '/help/business/using-noviopus?id=candidate-profile', title: 'navigation:constants.candidateProfile' },
  //   { href: '/help/business/using-noviopus?id=candidates', title: 'navigation:constants.candidates' },
  //   { href: '/help/business/using-noviopus?id=ad-campaigns', title: 'navigation:constants.adCampaigns' },
  //   { href: '/help/business/using-noviopus?id=reports', title: 'navigation:constants.reports' },
  //   { href: '/help/business/using-noviopus?id=settings', title: 'navigation:constants.settings' },
  // ],
};

export const faqMenuBusiness = {
  backRoute: '/help/business?id=help',
  backTitle: 'Business Help',
  // links: [
  //   { href: '/help/business/faq?id=new-account', title: 'navigation:constants.newAccount' },
  //   { href: '/help/business/faq?id=confirm-email', title: 'navigation:constants.confirmEmail' },
  //   { href: '/help/business/faq?id=confirm-email-issues', title: 'navigation:constants.confirmEmailIssues' },
  //   { href: '/help/business/faq?id=sign-in', title: 'navigation:constants.signIn' },
  //   { href: '/help/business/faq?id=reset-password', title: 'navigation:constants.resetPassword' },
  //   { href: '/help/business/faq?id=create-company', title: 'navigation:constants.createCompany' },
  //   { href: '/help/business/faq?id=create-job', title: 'navigation:constants.createJob' },
  //   { href: '/help/business/faq?id=manage-job', title: 'navigation:constants.manageJob' },
  //   { href: '/help/business/faq?id=account-settings', title: 'navigation:constants.accountSettings' },
  //   { href: '/help/business/faq?id=change-password', title: 'navigation:constants.changePassword' },
  //   { href: '/help/business/faq?id=languages', title: 'navigation:constants.languages' },
  //   { href: '/help/business/faq?id=suspend-account', title: 'navigation:constants.suspendAccount' },
  //   { href: '/help/business/faq?id=delete-account', title: 'navigation:constants.deleteAccount' },
  // ],
};

export const careerMenuLinks = {
  backRoute: '/',
  backTitle: 'constants.home',
  // links: [
  //   { href: '/help/career?id=help', title: 'Career Help' },
  //   { href: '/help/career/using-noviopus', title: 'Using Career' },
  //   { href: '/help/career/faq', title: 'careerFaqTranslate:noviopusFaq' },
  //   { href: '/help/career?id=contact-us', title: 'helpCentreCareerTranslate:contactUs' },
  //   { href: '/help/business?id=help', title: 'helpCentreCareerTranslate:businessHelpCenter' },
  // ],
};

export const businessMenuLinks = {
  backRoute: '/',
  backTitle: 'constants.home',
  // links: [
  //   { href: '/help/business?id=help', title: 'Business Help' },
  //   { href: '/help/business/using-noviopus', title: 'Using Business' },
  //   { href: '/help/business/faq', title: 'businessFaqTranslate:noviopusFaq' },
  //   { href: '/help/business?id=contact-us', title: 'helpCentreBusinessTranslate:contactUs' },
  //   { href: '/help/career?id=help', title: 'helpCentreBusinessTranslate:careerHelpCenter' },
  // ],
};

export const helpMenuLinks = {
  backRoute: '/',
  backTitle: 'constants.home',
  links: [
    {
      title: 'navigation:constants.terms',
      href: '/terms',
      icon: <Gavel />,
      activeIcon: (
        <>
          {gradient}
          <Gavel style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'navigation:constants.privacyPolicy',
      href: '/privacy',
      icon: <Policy />,
      activeIcon: (
        <>
          {gradient}
          <Policy style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

export const privacyTermsMenu = {
  backRoute: '/',
  backTitle: 'constants.home',
  links: helpMenuLinks,
};

export const getRoutes = isAuth => [
  {
    path: '/',
    menu: isAuth ? signedInHomeMenu : homeMenu,
  },
  {
    path: '/about',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/company/:id',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/company/:id/jobs',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/jobs/:id',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/search',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/applications',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/search-results',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/account/profile/edit',
    menu: settingsMenu,
  },
  {
    path: '/account/resume/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/education/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/experience/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/skills/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/languages/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/network/edit',
    menu: socialProfileMenu,
  },
  {
    path: '/account/add-contacts/edit',
    menu: socialProfileMenu,
  },
  {
    path: '/account/interests/edit',
    menu: socialProfileMenu,
  },
  {
    path: '/account/job-search/edit',
    menu: jobPreferencesMenu,
  },
  {
    path: '/account/workplace/edit',
    menu: jobPreferencesMenu,
  },
  {
    path: '/career/job-search/edit',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/career/workplace/edit',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/account/security',
    menu: accountMenu,
  },
  {
    path: '/account/language',
    menu: accountMenu,
  },
  {
    path: '/account/status',
    menu: accountMenu,
  },
  {
    path: '/terms',
    menu: privacyTermsMenu,
  },
  {
    path: '/privacy',
    menu: privacyTermsMenu,
  },
  // {
  //   path: '/help/career',
  //   menu: isAuth ? careerMenuLinks : homeMenu,
  // },
  // {
  //   path: '/help/business',
  //   menu: isAuth ? businessMenuLinks : homeMenu,
  // },
  // {
  //   path: '/help/career/using-noviopus',
  //   menu: usingNoviopusMenuCareer,
  // },
  // {
  //   path: '/help/career/faq',
  //   menu: faqMenuCareer,
  // },
  // {
  //   path: '/help/business/using-noviopus',
  //   menu: usingNoviopusMenuBusiness,
  // },
  // {
  //   path: '/help/business/faq',
  //   menu: faqMenuBusiness,
  // },
  // {
  //   path: '/help/career',
  //   menu: helpMenuLinks,
  // },
];

export const systemLanguages = [
  { name: 'English', value: 'en' },
  { name: 'Hebrew', value: 'he' },
];

if (i18n.isInitialized) {
  updateUserProfileMenu();
  updateSettingsMenu();
  updateSignedInCareerMenu();
  updateHomeMenu();
  updateCareerProfileMenu();
  updateJobPreferencesMenu();
  updateSocialProfileMenu();
  updateAccountMenu();
  updateCareerMenu();
  updateHelpMenu();
}

i18n.on('loaded', () => {
  updateUserProfileMenu();
  updateSettingsMenu();
  updateSignedInCareerMenu();
  updateHomeMenu();
  updateCareerProfileMenu();
  updateJobPreferencesMenu();
  updateSocialProfileMenu();
  updateAccountMenu();
  updateCareerMenu();
  updateHelpMenu();
});

i18n.on('languageChanged', () => {
  updateUserProfileMenu();
  updateSettingsMenu();
  updateSignedInCareerMenu();
  updateHomeMenu();
  updateCareerProfileMenu();
  updateJobPreferencesMenu();
  updateSocialProfileMenu();
  updateAccountMenu();
  updateCareerMenu();
  updateHelpMenu();
});
